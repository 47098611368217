import { ExclamationCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Col, Divider, Empty, Form, Input, Modal, notification, Row, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import lib, { myRequest } from "../../assets/utils/lib";
import { enrolleeListCommand, hasEmptyEntries, same } from "../../assets/utils/Helpers";
import { policy_type, states } from "../../assets/utils/config";
import { ColorRing } from "react-loader-spinner";
import logom from '../../assets/images/icon/amanhmo.svg';
import { uri_img } from "../../assets/utils/http-request";
import PopConfirm from "./PopConfirm";
import Checkbox from "./CheckBox";
import { EnroleeBiodataModal } from "./enroleeData";
import useModal from "antd/es/modal/useModal";


export default function Register({ handleCancel, isModalVisible, user, ...props }) {
    const fieldsOfInterest = ['enrolee_size', 'insurance_package', 'name', 'premium_type', 'category', 'state', 'city', 'policy_no',];
    const [form] = Form.useForm();
    const [values, setValues] = useState({});
    const [packages, setPlans] = useState([])
    const [prices, setPrices] = useState({})
    const [copyValue,] = useState(values);
    const [loading, setLoading] = useState(false);
    const [error,] = useState(false);
    const [updateChecbox, setUpdateCheckbox] = useState(false)
    const [modal, contextHolder] = useModal();

    const [addedHeads, setAddedHeads] = useState(
        user?.premium_type === "family" ?
            Number(user?.enrolee_size) - 6 > 0 ? Number(user?.enrolee_size) - 6 : 0
            : Number(user?.enrolee_size) - 1 > 0 ? Number(user?.enrolee_size) - 1 : 0
    );

    const removeActivationFlags = (values) => {
        // setValues(() => {
            const newObj = {}
            Object.keys(values)
                .filter((key) => [...fieldsOfInterest, 'policy_cost'].includes(key))
                // .filter(v => !['status', 'update_required', 'updated'].includes(v))
                .forEach((key) => {
                    newObj[key] = user[key]
                })
            return newObj
        // })
    }

    const handleActivationFlag = e => {
        if(e){
            setValues(d => ({...d, status: 1, update_required: 0, updated: 1}))
         }else{
            removeActivationFlags()
        }
        setUpdateCheckbox(e)
    }

    useEffect(() => {
        if (isModalVisible) {
            setValues(
                Object.keys(user)
                    .filter((key) => fieldsOfInterest.includes(key))
                    .reduce((obj, key) => {
                        return Object.assign(obj, {
                            [key]: user[key]
                        });
                    }, {})
            )
            // if(user?.category === 'corporate'){
            //     setLoading(true)
            //     setPlans([])
            //     window.plansWithSummary = []
            //     const social_no = user?.social_no;
            //     myRequest('post', 'validate_org_code', { social_no })
            //     .then(resp => resp.data)
            //     .then(data => {
            //         if (data['error'] ){
            //             notification.error({message: data?.message})
            //         } else {
            //             myRequest('post', 'orgPlansReg', { id: data?.data?.org_id})
            //             .then(data => data?.data)
            //             .then(data => {
            //                 if(data?.error){
            //                     notification.error({message: data?.message})
            //                 }else{
            //                     setPlans(data?.data?.map(item => ({label: item.plan_name.toUpperCase(), value: item.plan_name.toLowerCase()})))
            //                     const priceItems = {};
            //                     for (const item of data?.data) {
            //                         priceItems[item.plan_name.toUpperCase()] = {price: Number(item.plan_cost), sixPrice: Number(item.plan_six_cost)}
            //                     }
            //                     setPrices(priceItems)
            //                 }
            //             })
            //         }
            //     })
            //     .finally(() => setLoading(false))
            // }else if(user?.category === 'self pay'){
            setLoading(true)
            myRequest('post', 'orgPlansPublic')
                .then(data => data?.data)
                .then(data => {
                    if (data?.error) {
                        notification.error({ message: data?.message })
                    } else {
                        setPlans(data?.data?.map(item => ({ label: item.plan_name.toUpperCase(), value: item.plan_name.toLowerCase() })))
                        const priceItems = {};
                        for (const item of data?.data) {
                            priceItems[item.plan_name.toUpperCase()] = { price: Number(item.plan_cost), sixPrice: Number(item.plan_six_cost) }
                        }
                        setPrices(priceItems)
                    }
                }).finally(() => setLoading(false))
            //     }
            // console.log(user)
        }
    }, [isModalVisible]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        console.log(updateChecbox)
        if (values) {
            if (updateChecbox) {
                setValues(d => ({ ...d, status: 1, update_required: 0, updated: 1 }))
            } else {
            }
        }
    }, [updateChecbox]) // eslint-disable-line react-hooks/exhaustive-deps

    const confirm = (values) => {   
        values = removeActivationFlags(values)

        var updateCheckboxValue = false;

        const updateUpdateCheckboxValue = (value) => {
            updateCheckboxValue = value;
        }

        const submit = () => {
            const data = {...values, ...updateCheckboxValue? {status: 1, update_required: 0, updated: 1} : {} }
            handleUpdate(data)
        }

        modal.confirm({
            title: <span><strong>Account Update Summary</strong></span>,
            icon: <ExclamationCircleOutlined />,
            content: <>
                <Row>
                    {
                        Object.keys(values).map(key => {
                            return (
                                <Col span={24} style={{ margin: '2px 0', padding: '5px 0' }}>
                                    <Row justify='space-between'>
                                        <Col span={12}>{key.split('_').join(' ').toLocaleUpperCase()}:</Col>
                                        <Col span={12}>{key === 'policy_cost' ? <CurrencyFormat value={values[key]} displayType={'text'} thousandSeparator={true} prefix={'₦'} /> : values[key]}</Col>
                                    </Row>
                                </Col>
                            )
                        })
                    }
                </Row>
                <Divider style={{ padding: 0, margin: 0 }} />
                <p style={{ color: 'red' }}><strong>Are you sure all the data entered are correct and you want to go ahead with the submission?</strong></p>
                {
                    !Number(user?.status) &&
                    <p>
                        <Checkbox label={'Activate this policy'} onChange={(checked) => {setUpdateCheckbox(checked); updateUpdateCheckboxValue(checked)}} checked={updateCheckboxValue} />
                    </p>
                }
            </>,
            okText: 'Yes',
            onOk: submit,
            cancelText: 'No',
        });
    };

    const confirm1 = (values) => {
        values = removeActivationFlags(values)

        var updateCheckboxValue = false;

        const updateUpdateCheckboxValue = (value) => {
            updateCheckboxValue = value;
        }

        const submit = () => {
            const data = {...values, ...updateCheckboxValue? {status: 1, update_required: 0, updated: 1} : {} }
            handleUpdate(data)
        }

        modal.confirm({
            title: <span><strong>Account Update Summary</strong></span>,
            icon: <ExclamationCircleOutlined />,
            content: <>
                <Row>
                    {
                        Object.keys(values).map(key => {
                            return (
                                <Col span={24} style={{ margin: '2px 0', padding: '5px 0' }}>
                                    <Row justify='space-around'>
                                        <Col span={12}>{key.split('_').join(' ').toLocaleUpperCase()}:</Col>
                                        <Col span={12}>{key === 'policy_cost' ? <CurrencyFormat value={values[key]} displayType={'text'} thousandSeparator={true} prefix={'₦'} /> : values[key]}</Col>
                                    </Row>
                                </Col>
                            )
                        })
                    }
                </Row>
                <Divider style={{ padding: 0, margin: 0 }} />
                <p style={{ color: 'gold' }}><strong>You have not make any changes to this form data, are you sure you want to submit the form as is?</strong></p>
                {
                    !Number(user?.status) &&
                    <p>
                        <Checkbox label={'Activate this policy'} onChange={(checked) => {setUpdateCheckbox(checked); updateUpdateCheckboxValue(checked)}} checked={updateCheckboxValue} />
                    </p>
                }
            </>,
            okText: 'Yes',
            onOk: submit,
            cancelText: 'No',
        });
    };

    const handleOk = () => {
        setLoading(true);
        var data = { ...values, ...updateChecbox ? { status: 1, update_required: 0, updated: 1 } : {} };
        lib.updateUserDataCopy(data).then(res => res.data).then(data => {
            if (!data?.error) {
                notification.success({
                    message: data?.message
                })
                handleCancel();
                props?.command(user, { updateSuccess: true });
            }
        }).catch(err => {
            setLoading(false);
        })

        return 1
    };

    const handleUpdate = (values) => {
        setLoading(true)
        lib.updateUserData(values).then(res => res.data).then(data => {
            if (!data?.error) {
                notification.success({
                    message: data?.message
                })
                handleCancel();
                props?.command(user, { updateSuccess: true });
            }
        }).catch(err => {
            console.log(err)
            notification.error({ message: err?.msg || err?.message })
        }).finally(() => setLoading(false))

        return 1
    };

    const checkData = () => {
        const updated = !same(values, copyValue);
        if (hasEmptyEntries(values)) {
            Modal.error({
                title: 'Empty Data Field',
                icon: <ExclamationCircleOutlined />,
                content: <>All Fields must be properly filled</>,
                okText: 'Ok'
            });
            return;
        }

        switch (values['premium_type']) {
            case 'family':
                values['enrolee_size'] = 6 + Number(addedHeads);
                break;
            case 'individual':
                values['enrolee_size'] = 1 + Number(addedHeads);
                break;
            default: break;
        }

        values['policy_cost'] = values['premium_type'] === 'family' ?
            prices[values['insurance_package'].toUpperCase()].sixPrice +
            (Number(values['enrolee_size']) - 6) * prices[values['insurance_package'].toUpperCase()].price :
            Number(values['enrolee_size']) * prices[values['insurance_package'].toUpperCase()].price

        if (updated) {
            confirm(values);
            return;
        }

        confirm1(values);
    }

    return (
        <>
            <Modal open={isModalVisible}
                destroyOnClose
                closable
                onCancel={handleCancel}
                footer={<>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {/* <di> */}
                        <Button type={'link'} onClick={handleCancel}>Close</Button>
                        {/* </Col> */}
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Button type={'primary'} onClick={checkData} disabled={loading}>Update</Button>
                            {
                                !user?.status && <PopConfirm
                                    title={<>Are you sure you want to enable this?</>}
                                    onConfirm={handleOk}
                                    // onCancel={handleCancel}
                                    // onConfirmFeedback={handleFeedback}
                                    // confirmText="Enable Policy"
                                    // cancelText="No"
                                    confirmType="confirm"
                                    triggerButtonText="Enable Policy"
                                    triggerButtonProps={{ type: 'danger', disabled: loading }}
                                    placement="top"
                                    theme="danger"
                                    locale="en"
                                    debounceTime={500}
                                />
                            }
                            {/* <Button type={'primary'} onClick={handleOk} disabled={loading} danger>Enable Policy</Button> */}
                        </div>
                    </div>
                </>}
            >
                <Form form={form} layout="vertical">
                    <Form.Item label="Insurance Package" required tooltip="Select Insurance Package" style={{ width: "100%" }}>
                        <Select value={values?.insurance_package?.toUpperCase()} onChange={e => setValues(d => ({ ...d, insurance_package: e }))} options={packages} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item label="Premium Type" required tooltip="Select premium type" style={{ width: "100%" }}>
                        <Select defaultValue="Select premium type" onChange={e => setValues(d => ({ ...d, premium_type: e }))} value={values?.premium_type} style={{ width: "100%", marginRight: "10px" }} options={policy_type} />
                    </Form.Item>
                    <Form.Item label="Full Name" required tooltip="Enter your Full name" style={{ width: "100%" }}>
                        <Input onChange={e => setValues(d => ({ ...d, name: e.target.value }))} autoFocus value={values?.name || values?.fullName} placeholder="Musa Umar" style={{ width: "100%", marginRight: "10px", borderColor: values?.name === '' ? 'red' : null }} />
                    </Form.Item>
                    <Form.Item
                        label={<span>Add additional dependents <br></br><span style={{ color: 'brown', fontSize: 15, fontWeight: 100 }}>Use this if you have more than the enrolees specifid by your package type i.e <strong>Family: 6 enrolees, Individual: 1 enrolee,</strong> principal included.</span></span>}
                        style={{ width: "100%" }}
                    >
                        <Select defaultValue="0" style={{ width: "100%", marginRight: "10px" }} onChange={e => setAddedHeads(e)} value={addedHeads} options={Array.from({ length: 11 }).map((item, index) => ({ label: index, value: index }))} />
                    </Form.Item>
                    <Form.Item label="State of Residence" required tooltip={{ title: 'Enter your state', icon: <InfoCircleOutlined /> }} style={{ width: "100%" }}>
                        <Select defaultValue={"Select state"} style={{ width: "100%", marginRight: "10px" }} onChange={e => { setValues(d => ({ ...d, state: e })); }} value={values?.state} options={states} />
                    </Form.Item>
                    <Form.Item label="City" required tooltip="Enter your City" style={{ width: "100%" }}>
                        <Input onChange={e => setValues(d => ({ ...d, city: e.target.value }))} value={values?.city} placeholder="Ogidilu" style={{ width: "100%", marginRight: "10px", borderColor: values?.city === '' ? 'red' : null }} />
                    </Form.Item>
                    {error ? <ErrorMessage message={error} /> : null}
                </Form>
            </Modal>
            {contextHolder}
            {/* {openUpdateModal ?? <ControlledModal open={openUpdateModal} data={values} submitHandler={e => handleUpdate(e)} />} */}
        </>
    )
}

export const ListOfDependent = ({ policy, dependentList = [], command = null, showModal, closeModal, loading = false, viewOnly = false }) => {
    const [dependents, setDependents] = useState(dependentList);
    const [loadingEnrollees, setLoadingEnrollees] = useState(loading);

    const localCommand = async () => {
        setLoadingEnrollees(true)

        enrolleeListCommand(policy).then(data => setDependents(data))
            .catch(error => {
                notification.error({
                    message: error?.message || error?.msg
                })
            })
            .finally(() => setLoadingEnrollees(false))
    }

    // Getting Enrollees
    useEffect(() => {
        if (!dependentList?.length) {
            if (command) {
                command()
            } else {
                localCommand();
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal open={showModal} onCancel={closeModal} onClose={closeModal} footer={null} closable destroyOnClose width={'80vw'}>
            <div className="site-card-wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '30px 0' }}>
                <Row gutter={[16, 16]} >
                    {
                        dependents?.length ?
                            dependents?.map((item, index) => <Enrollees data={item} policy={policy} command={command || localCommand} key={index} viewOnly={viewOnly} />) : <>
                                {loadingEnrollees ? <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="color-ring-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="color-ring-wrapper"
                                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>There No Enrolees For This User</>} />}
                            </>
                    }
                </Row>
            </div>
        </Modal>
    )
}

export const Enrollees = ({ data, command = null, ...props }) => {
    const item = { ...data, update_required: Number(Boolean(data?.update_required)), updated: Number(Boolean(data?.updated)), status: Number(Boolean(data?.status)) };
    const imgs = useRef(logom);
    const [showEnrolleeData, setShowEnrolleeData] = useState(false);

    const handleEnrolleeDataView = () => {
        setShowEnrolleeData(!showEnrolleeData)
    }

    let styleTitle = {
        color: "#3597d3",
        fontSize: "13px",
        marginRight: "16px",
    }

    let styleDesc = {
        color: "#000",
        fontSize: "13px",
        fontWeight: 'normal',
    }

    let imageStyle = {
        width: "150px",
        height: "150px",
        margin: '0 auto',
        marginTop: 10,
        borderRadius: '50%',
    }

    imgs.current = item.pic_name ? `${uri_img}${item.pic_name}` : logom

    return (
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <Badge.Ribbon
                text={
                    (parseInt(item?.status) && parseInt(item?.updated) && !parseInt(item?.update_required)) ? <>Active</> :
                        !parseInt(item?.status) && parseInt(item?.updated) && !parseInt(item?.update_required) ? <>Disabled: {[item?.status, item?.updated, item?.update_required].join('')}</> :
                            !parseInt(item?.status) && parseInt(item?.updated) && parseInt(item?.update_required) ? <>Submitted: {[item?.status, item?.updated, item?.update_required].join('')}</> :
                                !parseInt(item?.status) && !parseInt(item?.updated) && parseInt(item?.update_required) ? <>Inactive: {[item?.status, item?.updated, item?.update_required].join('')}</> :
                                    <>Error: {[item?.status, item?.updated, item?.update_required].join('')}</>
                }
                color={
                    (parseInt(item?.status) && parseInt(item?.updated) && !parseInt(item?.update_required)) ? 'rgb(9, 164, 121)' :
                        !parseInt(item?.status) && parseInt(item?.updated) && !parseInt(item?.update_required) ? 'red' :
                            !parseInt(item?.status) && parseInt(item?.updated) && parseInt(item?.update_required) ? 'skyblue' :
                                !parseInt(item?.status) && !parseInt(item?.updated) && parseInt(item?.update_required) ? 'grey' : "gold"
                }
            >
                <Card
                    hoverable
                    cover={<img style={imageStyle} src={imgs.current} alt='' />}
                    onClick={handleEnrolleeDataView}
                >
                    <Row gutter={[12, 2]}>
                        <Col span={24}>
                            <Row>
                                <Col span={10}>
                                    <b style={styleTitle}>Account Holder:</b>
                                </Col>
                                <Col span={14}>
                                    <span style={styleDesc}>
                                        {Array.from(item.first_name).map((i, j) => j === 0 ? i.toUpperCase() : i).join('')} &nbsp;
                                        {Array.from(item.surname).map((i, j) => j === 0 ? i.toUpperCase() : i).join('')}
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col span={10}>
                                    <b style={styleTitle}>Policy Role:</b>
                                </Col>
                                <Col span={14}>
                                    <span style={styleDesc}> {Array.from(item.role).map((i, j) => j === 0 ? i.toUpperCase() : i).join('')}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col span={10}>
                                    <b style={styleTitle}>Insurance ID:</b>
                                </Col>
                                <Col span={14}>
                                    <span style={styleDesc}>{item.insurance_no}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row >
                                <Col span={10}>
                                    <b style={styleTitle}>Primary Health Care:</b>
                                </Col>
                                <Col span={14}>
                                    <span style={styleDesc}>{item.primary_health_facility}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col span={10}>
                                    <b style={styleTitle}>Secondary Health Care:</b>
                                </Col>
                                <Col span={14}>
                                    <span style={styleDesc}> {item.secondary_health_facility}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Badge.Ribbon>
            <EnroleeBiodataModal data={data} policy={props?.policy} display={showEnrolleeData} action={() => setShowEnrolleeData(false)} key={props?.key} viewOnly={props?.viewOnly} command={command} />
        </Col>
    )
}

export const ControlledModal = ({ open = false, data = {}, submitHandler }) => {
    const [isModalVisible, setIsModalVisible] = useState(open); // State to control modal visibility
    const [isChecked, setIsChecked] = useState(false); // State to control checkbox

    // Show modal
    const showModal = () => {
        setIsModalVisible(true); // Open modal
    };

    // Handle modal OK button
    const handleOk = () => {
        // console.log('Checkbox checked:', isChecked); // Log the current checkbox state
        submitHandler(isChecked)
        setIsModalVisible(false); // Close modal
    };

    // Handle modal Cancel button
    const handleCancel = () => {
        setIsModalVisible(false); // Close modal
    };

    // Handle checkbox change
    const handleCheckboxChange = (e) => {
        setIsChecked(e); // Update checkbox state
    };

    return (
        // {/* Controlled Ant Design Modal */}
        <Modal
            title="Account Update Summary"
            open={isModalVisible} // Control modal visibility
            onOk={handleOk} // OK button action
            onCancel={handleCancel} // Cancel button action
            okText="Yes"
            cancelText="No"
        >
            <Row>
                {
                    Object.keys(data).map(key => {
                        return (
                            <Col span={24} style={{ margin: '2px 0', padding: '5px 0' }}>
                                <Row justify='space-around'>
                                    <Col span={12}>{key.split('_').join(' ').toLocaleUpperCase()}:</Col>
                                    <Col span={12}>{key === 'policy_cost' ? <CurrencyFormat value={data[key]} displayType={'text'} thousandSeparator={true} prefix={'₦'} /> : data[key]}</Col>
                                </Row>
                            </Col>
                        )
                    })
                }
            </Row>

            <Divider />

            <p style={{ color: 'red' }}>
                <strong>Are you sure all the data entered are correct and you want to go ahead with the submission?</strong>
            </p>

            {/* Controlled Checkbox */}
            {!Number(data?.status) && <p>
                <Divider />
                <Checkbox
                    checked={isChecked} // Controlled checkbox state
                    onChange={handleCheckboxChange} // Update checkbox state on change
                >
                    Activate this policy
                </Checkbox> </p>}
        </Modal>
    );
};